<template>
  <div class="container card p-5">
    <div class="row text-center">
      <div class="col-3 mx-auto">
        <h4 class="fw-bold">個人情報保護方針</h4>
      </div>
    </div>
    <div class="row text-start mt-4">
      <div class="col-6 mx-auto">
        <p>
          公益財団法人日本テニス協会（以下「本協会」いう。）は、平成 29 年 5 月 30
          日に施行された改正個人情報保護法に基づき、自ら取り扱う個人情報を適切に保護すること、安全に管理することが、本協会の社会的責任であることを認識し、以下のとおり個人情報保護方針を定め、「個人情報の保護に関する法律」及び「行政手続における特定の個人を識別するための
          番号の利用等に関する法律」並びに関係法令・規則、その他の規範等および本協会の定める個人情報保護に関する諸規程等を遵守し、個人情報の保護を推進します。
        </p>
        <uL>
          <li>
            <p>
              １. 基本姿勢 本協会は、</p>
            <p>
              本個人情報保護方針を実行・維持するために、個人情報等の適正な取扱いと保護に関する協会内規程及び体制を定め、本協会の全ての役職員、ナショナルチームを含む本部・委員会活動に参加するもの（以下「役職員等」）に周知徹底し、適切な運用がなされるよう維持、改善していきます。
            </p>
          </li>

          <li>
            <p>２ .個人情報の取得について</p>
            <p>
              本協会は、個人情報を取得する際は、事前にその利用目的を明確にしてご本人に通知しかつ公正な手段によって個人情報を取得します。
            </p>
          </li>

          <li>
            <p>3. 個人情報の利用目的について</p>
            <p>
              本協会は個人情報について、以下の利用目的の範囲内又はその取得状況から明らかである利用目的の範囲内で利用し、ご本人の同意がある場合又は法令で認められている場合を除き他の目的で利用しません。
            </p>
            <ul>
              <li><p>（1）テニス競技の普及、指導・育成および振興を図る公益目的の達成のため、本協会関連業務を遂行するため。 </p></li>
              <li><p>（2）国内・国際テニス競技会の主催及び国内で開催されるテニス競技会の後援・公認に関する情報提供、勧誘行為を行うため。</p></li>
              <li><p>
                （3）その他上記２項に関する本協会の関連業務を安全かつ確実に遂行するため。また本協会は個人情報に関し利用目的の通知を求められたときは、遅滞なく、これを通知します。但し以下の場合に該当する場合はこの限りではありません。</p>
              </li>
              <li><p>（1）利用目的が明らかな場合。</p></li>
              <li><p>（2）利用目的を通知しまたは公表することにより、ご本人または第三者の生命、身体、財産その他権利利益を害する恐れがある場合。</p></li>
              <!--                            <li><p>（3）利用目的をご本人に通知または公表することにより、本協会の権利または正当な利益を害する場合。 </p></li>-->
              <!--                            <li><p>（4）国の機関または地方公共団体が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用目的をご本人に通知しまたは公表することにより当該事務の 遂行に支障を及ぼすおそれがある場合。</p></li>-->
            </ul>
          </li>
        </uL>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
}
</script>

<style scoped>
.container {
  margin-bottom: 7rem !important;
}

li {
  list-style-type: none;
}
</style>